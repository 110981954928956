import { SchemaState } from '@agentero/dynamic-form';
import { Address } from '@agentero/dynamic-form/shared';
import { Agent, CarriersSearch } from '@agentero/models';
import { AgenciesSearch } from '@agentero/models/agency';
import { AgentsSearch, Campaign } from '@agentero/models/agent';
import { InsuranceType } from '@agentero/models/opportunity';
import { Carrier, getLandingUrlFromCarrier } from '@agentero/models/shared';

import { getAgenciesQueryString } from 'routes/getAgenciesQueryString';
import { getAgentsQueryString } from 'routes/getAgentsQueryString';
import { getCarriersQueryString } from 'routes/getCarriersQueryString';

//Activate
export const activateRoute = '/activate';

// Sign in
export const signInRoute = '/experts/sign_in';

//Forgot password
export const forgotPasswordRoute = '/forgot-password';

// Dashboard
export const dashboardRoute = '/dashboard';

// Quotes (Market access)
export const quotesRoute = '/quotes';

// Applications
export const applicationsRoute = '/applications';
export const commercialApplicationsRoute = '/commercial-applications';

const applicationsNewRoute = '/applications/new';
export const getApplicationsNewRoutePath = (
	insuranceType: InsuranceType,
	address: Address,
	schemaState: SchemaState,
	relations?: {
		leadId?: string;
	}
) => {
	const search = new URLSearchParams();
	search.append('insuranceType', insuranceType);

	const addressQuery = encodeBase64(address);
	search.append('address', addressQuery);

	const stateQuery = encodeBase64(schemaState);
	search.append('state', stateQuery);

	if (relations?.leadId) {
		search.append('leadId', relations.leadId);
	}

	return `${applicationsNewRoute}?${search.toString()}`;
};

const encodeBase64 = <T extends {}>(value: T) =>
	Buffer.from(JSON.stringify(value)).toString('base64');

// Application Details
export const applicationDetailsRoute = '/applications/[applicationId]';
export const getApplicationDetailsRoutePath = (id: string, params?: string[]) =>
	params && params.length > 0 ? `/applications/${id}?${params.join('&')}` : `/applications/${id}`;
export const applicationDetailsRiskInfoRoute = '/applications/[applicationId]?riskInfoTab=true';

// Quote Details
export const quoteDetailsRoute = '/applications/[applicationId]/quotes/[quoteId]';
export const getQuoteDetailsRoutePath = (
	applicationId: string,
	quoteId: string,
	params?: string[]
) =>
	params && params.length > 0
		? `/applications/${applicationId}/quotes/${quoteId}?${params.join('&')}`
		: `/applications/${applicationId}/quotes/${quoteId}`;

// Binders
export const bindersRoute = '/binders';
export const getBindRequestRoutePath = (binderId: string) => `${bindersRoute}/${binderId}`;

const lobTypeRouteMapping: { [key in InsuranceType]?: string } = {
	[InsuranceType.Homeonwners]: 'homeowners',
	[InsuranceType.PersonalAuto]: 'auto',
	[InsuranceType.Commercial]: 'commercial'
};

export const lobTypeRouteLabelKeys: { [key in string]?: string } = {
	['homeowners']: 'insuranceTypes.homeowners',
	['auto']: 'insuranceTypes.personalAuto',
	['commercial']: 'insuranceTypes.commercial'
};

export const fromStringToInsuranceTypeKeys: { [key in string]?: InsuranceType } = {
	['homeowners']: InsuranceType.Homeonwners,
	['home_owner']: InsuranceType.PersonalAuto,
	['auto']: InsuranceType.PersonalAuto,
	['personal_auto']: InsuranceType.PersonalAuto,
	['commercial']: InsuranceType.Commercial
};

// Rater
export const raterPath = '/quotes/rater/[insuranceType]';
export const raterRoute = '/quotes/rater';
export const homeownersRaterRoute = `${raterRoute}/homeowners`;
export const autoRaterRoute = `${raterRoute}/auto`;
export const commercialRaterRoute = `${raterRoute}/commercial`;
export const raterFaqsBindWithAgentero = '/quotes/rater/faqs/bind-with-agentero';
export const bwaQuestionsRoute = `${raterRoute}/bwa-questions`;

export const getUrlFromLOB = (lobsQuotableList: InsuranceType[]): string =>
	lobsQuotableList.length === 1
		? getRaterInsuranceTypeRouteMapping({ insuranceType: lobsQuotableList.at(0) }) ?? raterRoute
		: raterRoute;

export const getRaterInsuranceTypeRouteMapping = ({
	insuranceType
}: {
	insuranceType: InsuranceType;
}) =>
	lobTypeRouteMapping[insuranceType]
		? `${raterRoute}/${lobTypeRouteMapping[insuranceType]}`
		: undefined;

export const getBwAQuestionsQueryString = (
	rateId: string,
	carrier: string,
	insuranceType: string
) => `${bwaQuestionsRoute}?rateId=${rateId}&carrier=${carrier}&insuranceType=${insuranceType}`;
// Carriers
export const carriersRoute = '/carriers';

export const getCarriersRouteQueryString = (search: CarriersSearch) => {
	const queryString = getCarriersQueryString(search);
	return `${carriersRoute}${queryString}`;
};

export const carrierCredentialsRoute = '/carriers/manage-credentials';
export const carriersBwARoute = `/carriers/bwa-carriers`;
export const carrierDetails = `/carriers/[carrier]`;
export const getCarrierDetailsPath = (carrier: string) => `/carriers/${carrier}`;
export const carrierLanding = `/carriers/landing/[carrier]`;
export const getCarrierLandingPath = (carrier: Carrier) =>
	`/carriers/landing/${getLandingUrlFromCarrier(carrier)}`;
export const appetiteRoute = '/carriers/appetite';
export const getCarrierCredentialsPath = (carrier: string) =>
	`${carrierCredentialsRoute}?carrier=${carrier}`;
export const adminCarriersRoute = '/admin/carriers';
export const adminCarriersEditRoute = '/admin/carriers/[carrier]/edit';
export const carrierHighlightsRoute = '/carriers/[carrier]/highlights';
export const getCarrierHighlightsPath = (carrier: string) => `/carriers/${carrier}/highlights`;

export const getAdminCarriersEditPath = (carrier: string) => `/admin/carriers/${carrier}/edit`;

export const getAdminCarriersAppetiteResourcesPath = (carrier: string) =>
	`/admin/carriers/${carrier}/appetite-resources`;

// Commissions
export const commissionsRoute = '/commissions';
export const adminCommissionsRoute = '/admin/agency-commissions';
export const commissionsFAQCarriersCommissionsRoute = '/commissions/faqs/carriers-commissions';

// Agency carriers mapping
//TODO: Use/agencies/[agencyId]/agency-carriers/edit when agencies doesn't exist in back-ag to follow he rules edits
export const agencyCarriersRoute = '/agency-carriers';

// Agencies
export const agenciesRoute = '/agencies';
export const agencyDetailsRoute = `${agenciesRoute}/[agencyId]`;
export const getAgencyDetailsRoutePath = (id: string) => `${agenciesRoute}/${id}`;
export const getAgencyBwaCarrierApprovalPath = (id: string, carrier: string) =>
	`${agenciesRoute}/${id}/${carrier}/bwa-approval`;
export const getAgenciesRouteQueryString = (search: AgenciesSearch) => {
	const queryString = getAgenciesQueryString(search);
	return `${agenciesRoute}${queryString}`;
};
export const agencyNewRoute = `${agenciesRoute}/new`;
export const getContractRoutePath = (agent: Agent) => `/agencies/${agent.agency.id}/contract`;

export const contactInfoEditRoute = `/contact-information/edit`;

export const brandedAppEditRoute = `/branded-app/edit`;

export const agencyProfileSettingsEditRoute = `/settings/edit`;

export const getAgencyProfileCommissionsEditRoute = (id: string) =>
	`/admin/agency-commissions?agencyId=${id}`;
export const agencyInfoEditRoute = `/agency-information/edit`;

// Agency agents
export const getAgencyAgentDetailsPath = (id: string) => `/members/${id}`;

export const newMemberRoute = `/members/new`;

// Agents
export const agentsRoute = '/agents';
export const getAgentsRouteQueryString = (search: AgentsSearch) => {
	const queryString = getAgentsQueryString(search);
	return `${agentsRoute}${queryString}`;
};
export const agentDetailsRoute = `${agentsRoute}/[agentId]`;
export const getAgentDetailsRoutePath = (id: string) => `${agentsRoute}/${id}`;
export const agentNewRoute = `${agentsRoute}/new`;
export const agentProfileRoute = `${agentsRoute}/[agentId]`;
export const getAgentProfilePath = (agentId: string) => `${agentsRoute}/${agentId}`;
export const generalInfoEditRoute = `/general-information/edit`;

export const signatureEditRoute = `/signature/edit`;

export const mobileAppEditRoute = `/mobile-app/edit`;

export const integrationEditRoute = `/integration/edit`;

export const carrierCredentialsEditRoute = `/carrier-credentials/edit`;

export const passwordEditRoute = `/password/edit`;

export const licenseRoute = '/license';
export const licenseEditRoute = '/license/edit';

export const resetPasswordRoute = '/reset-password';
export const updatePasswordRoute = '/update-password';

//Admin
export const adminsRoute = '/admin';
export const adminDetailsRoute = `${adminsRoute}/[agentId]`;
export const getAdminDetailsRoutePath = (id: string) => `${adminsRoute}/${id}`;
export const editRoute = '/edit';

// Settings
export const isUnderSettings = (path: string) => path.startsWith(settingsRoute);
export const settingsRoute = '/settings';

export const settingsAgentDetailsRoute = `${settingsRoute}${agentsRoute}/[agentId]`;
export const getSettingsAgentDetailsRoutePath = (id: string) =>
	`${settingsRoute}${agentsRoute}/${id}`;

export const settingsAdminDetailsRoute = `${settingsRoute}${adminsRoute}/[agentId]`;
export const getSettingsAdminDetailsRoutePath = (id: string) =>
	`${settingsRoute}${adminsRoute}/${id}`;

export const settingsAgencyDetailsRoute = `${settingsRoute}${agenciesRoute}/[agencyId]`;
export const getSettingsAgencyDetailsRoutePath = (id: string) =>
	`${settingsRoute}${agenciesRoute}/${id}`;

const productionDataRoute = 'production-data';

export const settingsProductionDataRoute = `${settingsRoute}${agenciesRoute}/[agencyId]/${productionDataRoute}`;
export const getSettingsProductionDataRoutePath = (id: string) =>
	`${settingsRoute}${agenciesRoute}/${id}/${productionDataRoute}`;
export const getSettingsLossRatioEditRoutePath = (id: string, redirectUrl?: string) =>
	`${getSettingsProductionDataRoutePath(id)}/loss-data-edit${
		redirectUrl ? `?redirect_url=${redirectUrl}` : ''
	}`;
export const getSettingsBookSplitEditRoutePath = (id: string, redirectUrl?: string) =>
	`${getSettingsProductionDataRoutePath(id)}/book-split-edit${
		redirectUrl ? `?redirect_url=${redirectUrl}` : ''
	}`;

export const settingsAgentCarrierCredentialsRoute = `${settingsRoute}${carriersRoute}/manage-credentials`;
export const settingsAgentNotificationsRoute = `${settingsRoute}/notifications`;
export const settingsTiersAndBillingRoute = `${settingsRoute}/tiers-and-billing`;

export const getSettingsAgentCarrierCredentialsRoutePath = () =>
	`${settingsRoute}${carriersRoute}/manage-credentials`;

export const getSettingsAgentChangePasswordRoutePath = (id: string) =>
	`${settingsRoute}${agentsRoute}/${id}/password/edit`;

export const settingsAgentChangePasswordRoute = `${settingsRoute}${agentsRoute}/[agentId]/password/edit`;

export const getSettingsAgentLicenseRoutePath = (id: string) =>
	`${settingsRoute}${agentsRoute}/${id}${licenseRoute}`;

export const settingsAgentLicenseEditRoute = `${settingsRoute}${agentsRoute}/[agentId]${licenseEditRoute}`;
export const getSettingsAgentLicenseEditRoutePath = (id: string) =>
	`${settingsRoute}${agentsRoute}/${id}${licenseEditRoute}`;

export const getSettingsAgencyLicenseRoutePath = (id: string) =>
	`${settingsRoute}${agenciesRoute}/${id}${licenseRoute}`;

export const settingsAgencyLicenseRoute = `${settingsRoute}${agenciesRoute}/[agencyId]${licenseEditRoute}`;
export const getSettingsAgencyLicenseEditRoutePath = (id: string) =>
	`${settingsRoute}${agenciesRoute}/${id}${licenseEditRoute}`;

export const getSettingsAgencyEORoutePath = (id: string) =>
	`${settingsRoute}${agenciesRoute}/${id}/errors-and-omissions/edit`;

//ACCOUNT VERIFICATION
export const accountVerificationRoute = '/account-verification';
export const accountVerificationCompletedRoute = '/account-verification/completed';

// APPOINTMENT REQUESTS
export const appointmentRequestRoute = (carrier: string) =>
	`/carriers/appointments-requests/${carrier}`;
export const adminAppointmentRequestsRoute = '/admin/appointment-requests';
export const adminAppointmentRequestsDetailRoute = (agencyId: string, carrier: string) =>
	`/admin/appointment-requests/${agencyId}/${carrier}`;

// TIERING
export const paymentRoute = '/payment';
export const getPaymentPath = (redirectUrl: string) =>
	`${paymentRoute}?redirect_url=${redirectUrl}`;

// LEADS
export const leadsRoute = '/leads';

export const resolveRelative = (from: string, to: string) => {
	if (to.startsWith('/')) to = to.slice(1);

	const normalizedPathSegments = [...from.split('/'), ...to.split('/')];

	const segments = normalizedPathSegments.reduce((resolvedPathSegments, segment) => {
		if (segment === '.') {
		} else if (segment === '..') {
			resolvedPathSegments.pop();
		} else {
			resolvedPathSegments.push(segment);
		}

		return resolvedPathSegments;
	}, []);

	return segments.join('/');
};

// CAMPAIGNS

const mapCampaignsRoute = {
	[Campaign.CAMPAIGN_MORE_TWO_POLICIES_MONTH]: 'two-policies-per-month',
	[Campaign.CAMPAIGN_LESS_TWO_POLICIES_MONTH]: 'two-policies-per-month'
};

export const campaignsRoute = (campaign: Campaign, redirectUrl?: string) =>
	`${settingsTiersAndBillingRoute}/campaigns/${mapCampaignsRoute[campaign]}${
		redirectUrl ? `?redirect_url=${redirectUrl}` : ''
	}`;

export const landingiBwa = '/quotes/rater/faqs/bind-with-agentero';
